import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom'

import { Helmet, HelmetProvider } from 'react-helmet-async'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Homepage from './pages/Homepage'

const theme = createTheme({})

const ROBINHOOD = 'https://join.robinhood.com/masaok1'

const App = () => {
  return (
    <HelmetProvider>
      <CssBaseline />
      <Helmet>
        <title>Masao Codes</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              path="/rob1"
              element={() => {
                window.location.replace('https://join.robinhood.com/masaok1')
                return null
              }}
            />
            <Route
              path="/rob2"
              element={() => {
                var link = document.createElement('a')
                link.href = 'https://join.robinhood.com/masaok1'
                document.body.appendChild(link)

                link.click()
                return null
              }}
            />
            <Route path="/rob4" element={<Navigate replace to={ROBINHOOD} />} />

            <Route path="//*" element={<Homepage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
